import { gql } from '@apollo/client'
import Image from 'next/image'
import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import contentful from '../lib/contentful'
import shopify from '../lib/shopify'
import { GTagContext } from '../providers/GTagContext'

const filterHiddenProducts = (edges: any[]) => {
  return edges.filter((edge: any) => {
    if (!edge.node.hideFromSearch) return true
    return edge.node.hideFromSearch.value === 'false'
  })
}

const Search = ({
  placeholder,
  includeCollections,
}: {
  placeholder: string
  includeCollections?: string[]
}) => {
  const [query, setQuery] = useState('')
  const [products, setProducts] = useState<any[]>([])
  const [posts, setPosts] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const { dispatch } = useContext(GTagContext)

  const debouncedRefetch = useDebouncedCallback(
    (query: string, includeCollections) => {
      const shopifyCompliantQuery = `*${query}*`
      const productSearch = shopify
        .query({
          query: gql`
            query search($query: String!) {
              products(first: 250, query: $query, sortKey: BEST_SELLING) {
                edges {
                  node {
                    id
                    title
                    handle
                    variants(first: 1) {
                      edges {
                        node {
                          id
                          image {
                            id
                            url
                            altText
                            width
                            height
                          }
                        }
                      }
                    }
                    collections(first: 250) {
                      edges {
                        node {
                          id
                          handle
                        }
                      }
                    }
                    hideFromSearch: metafield(
                      namespace: "teatips"
                      key: "hide_from_search"
                    ) {
                      value
                    }
                  }
                }
              }
            }
          `,
          variables: { query: shopifyCompliantQuery },
        })
        .then(({ data }) => data.products.edges)
        .then((edges: any[]) => {
          return filterHiddenProducts(edges)
        })
        .then((edges: any[]) => {
          if (!includeCollections || includeCollections.length <= 0)
            return edges

          return edges.filter((edge: any) => {
            const productCategoriesHandles = edge.node.collections.edges.map(
              (edge: any) => edge.node.handle
            )

            return includeCollections.some((collectionHanle: string) =>
              productCategoriesHandles.includes(collectionHanle)
            )
          })
        })
        .then((edges: any[]) => {
          setProducts(edges)
        })

      const postSearch = contentful
        .getEntries({
          select: 'sys.id',
          content_type: 'post',
          links_to_entry: '7dno5MJbQOEahkEEZlGRiF',
          query,
          include: 0,
        })
        .then(({ items }) => {
          return contentful.getEntries({
            content_type: 'page',
            select: 'fields.title,fields.slug',
            'fields.content.sys.id[in]': items
              .map((post: any) => post.sys.id)
              .join(','),
            include: 1,
          })
        })
        .then((entries: any) => {
          return entries.items.map(({ fields }) => ({
            title: fields.title,
            slug: fields.slug,
          }))
        })
        .then((posts: any[]) => {
          setPosts(posts)
        })

      return Promise.all([productSearch, postSearch]).finally(() => {
        dispatch({
          type: 'EVENT',
          payload: { type: 'search', content: { search_term: query } },
        })
        setLoading(false)
      })
    },
    500
  )

  useEffect(() => {
    if (query.length <= 2) return
    setLoading(true)
    debouncedRefetch(query, includeCollections)
  }, [query, debouncedRefetch, includeCollections])

  return (
    <div className="px-4 my-4">
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
        className={`w-full block border-2 border-teatips-light rounded-t text-sm md:text-base py-2 px-4 ${
          products.length === 0 ? 'rounded' : ''
        }`}
      />

      {query.length > 2 && (products.length > 0 || posts.length > 0) ? (
        <div className="bg-teatips-light p-4 rounded-b">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {products
              .map((edge) => edge.node)
              .map((product: any) => (
                <Link href={`/products/${product.handle}`} key={product.id}>
                  <a className="no-underline text-text">
                    <div className="mb-2 shadow-xl rounded overflow-hidden">
                      <Image
                        src={product.variants.edges[0].node.image.url}
                        alt={product.variants.edges[0].node.image.altText}
                        width={product.variants.edges[0].node.image.width}
                        height={product.variants.edges[0].node.image.height}
                        layout="responsive"
                        sizes="(max-width: 767px) 50vw, (max-width: 1023px) 33vw, 25vw"
                      />
                    </div>
                    <h3 className="font-serif text-xl text-center">
                      {product.title}
                    </h3>
                  </a>
                </Link>
              ))}
          </div>

          {products.length > 0 && posts.length > 0 ? (
            <hr className="border-teatips border-t-2 my-2" />
          ) : null}

          <div>
            <h2 className="mb-2">Risultati dal blog</h2>
            <ul className="mb-0">
              {posts.map((post) => (
                <Link href={post.slug} key={post.slug} passHref>
                  <li>
                    <a className="block cursor-pointer">{post.title}</a>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      ) : null}

      {!loading &&
      query.length > 2 &&
      products.length === 0 &&
      posts.length === 0 ? (
        <div className="bg-teatips-light p-4 rounded-b grid gap-4 text-base">
          Nessun prodotto trovato cercando &quot;{query}&quot;, prova con
          un&apos;altra ricerca.
        </div>
      ) : null}
    </div>
  )
}

export default Search
