const replaceMap = {
  '<p': '<p class="text-base min-h-[1rem]"',
  '<h1': '<h1 class="mb-2"',
  '<h2': '<h2 class="mb-4"',
  '<img': '<img class="rounded w-full block md:w-auto md:mx-auto md:mb-4"',
  '<iframe': '<iframe class="rounded"',
  'style="float: left;"': 'style="float: left; margin-right: 1rem; margin-bottom: 1rem;"',
  'style="float: right;"': 'style="float: left; margin-left: 1rem; margin-bottom: 1rem;"',
}

const createMarkup = (html: string) => {
  return {
    __html: Object.entries(replaceMap).reduce((acc, [key, value]) => {
      return acc.split(key).join(value)
    }, html),
  }
}

const PageBody = ({ body }) => {
  return (
    <div
      className="text-base mb-4 px-4"
      dangerouslySetInnerHTML={createMarkup(body)}
    ></div>
  )
}

export default PageBody
