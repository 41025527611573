import Link from 'next/link'
import Image from 'next/image'
import { loader } from '../lib/contentfulAssets'
import tw from 'tailwind-styled-components'
import { FiArrowRight } from 'react-icons/fi'

const createMarkup = (html: string) => {
  return {
    __html: html,
  }
}

const Grid = tw.a`
  grid-rows-[auto_auto_1fr_auto]
  gap-2
  grow
  justify-center
  md:w-full
  h-full
  grow
  shrink-0
  snap-center
  bg-teatips-light
  rounded
  cursor-pointer
  inline-grid
  p-4
  md:mr-0
  shadow-xl
  text-text
  no-underline
`

const Title = tw.h3`
  mt-2
  whitespace-normal
`

const Text = tw.div`
  whitespace-normal
  text-sm
`

const ReadMore = tw.span`
  flex
  justify-end
  align-center
  text-sm
  gap-2
  underline
  text-text
`

const BlogPostPreview = ({ fields }) => {
  return (
    <Link href={fields.slug} passHref={true}>
      <Grid>
        <Image
          src={fields.content.fields.cover.fields.file.url}
          alt={fields.content.fields.cover.fields.title}
          width={fields.content.fields.cover.fields.file.details.image.width}
          height={fields.content.fields.cover.fields.file.details.image.height}
          loading="lazy"
          loader={loader}
          className="rounded block w-full"
          layout="responsive"
          sizes="(max-width: 767px) 50vw, 25vw"
          quality={75}
        />

        <Title>{fields.title}</Title>
        <Text
          dangerouslySetInnerHTML={createMarkup(fields.content.fields.excerpt)}
        />
        <ReadMore>
          Leggi l&apos;articolo
          <FiArrowRight className="text-xl text-text" />
        </ReadMore>
      </Grid>
    </Link>
  )
}

export default BlogPostPreview
