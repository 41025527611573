import dynamic from 'next/dynamic'

const ShopHeader = dynamic(() => import('./ShopHeader'))
const ShopFooter = dynamic(() => import('./ShopFooter'))
const Cart = dynamic(() => import('./Cart'))

const ShopLayout = ({ children, collections }) => {
  return (
    <>
      <ShopHeader collections={collections} />

      <div className="container mx-auto text-text bg-background">
        <main className="pt-4">{children}</main>
        <Cart />
      </div>

      <ShopFooter />
    </>
  )
}

export default ShopLayout
