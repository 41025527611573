import Image from 'next/image'
import Link from 'next/link'

import tw from 'tailwind-styled-components'
import { ICollectionFields } from '../@types/generated/contentful'

const AnchorWrapper = tw.a`
  text-center
  text-text
  no-underline
  grid grid-rows-[auto_1fr]
  pointer
`

const ImageWrapper = tw.div`
  mb-2
  shadow-xl
  rounded
`

const Title = tw.h2`
  font-serif
  text-xl
`

const Collection = ({ cover, title, slug }: ICollectionFields) => {
  return (
    <Link href={`/collections/${slug}`} passHref>
      <AnchorWrapper>
        <ImageWrapper>
          <Image
            src={`https:${cover.fields.file.url}`}
            alt={cover.fields.title}
            layout="responsive"
            width={cover.fields.file.details.image.width}
            height={cover.fields.file.details.image.height}
            className="block rounded"
            sizes="(max-width: 767px) 50vw, (max-width: 767px) 33vw, 25vw"
          />
        </ImageWrapper>
        <Title>{title}</Title>
      </AnchorWrapper>
    </Link>
  )
}

export default Collection
