import { gql } from '@apollo/client'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import Head from 'next/head'
import Link from 'next/link'
import tw from 'tailwind-styled-components'
import BlogPostPreview from '../components/BlogPostPreview'
import Collection from '../components/Collection'
import PageBody from '../components/PageBody'
import Search from '../components/Search'
import ShopLayout from '../components/ShopLayout'
import contentful from '../lib/contentful'
import contentfulPreview from '../lib/contentfulPreview'
import shopify from '../lib/shopify'
import { FiArrowRight } from 'react-icons/fi'
import { Entry } from 'contentful'
import { ICollectionFields } from '../@types/generated/contentful'

const Title = tw.h1`
  font-serif
  mt-4
  mb-8
  px-4
  text-center
`

const UpperBandWrapper = tw.div`
  mb-4
  px-4
`

const UpperBand = tw.span`
  bg-teatips-light
  block
  px-4
  py-2
  text-center
  rounded
`

const CollectionsWrapper = tw.div<{ children: any }>`
  grid
  grid-cols-2
  md:grid-cols-3
  lg:grid-cols-4
  gap-4
  px-4
  mb-4
`

const Cleaner = tw.div`
  mb-0
  clear-both
`

const BlogPreviewWrapper = tw.div`
  snap-x
  snap-mandatory
  overflow-x-scroll
  grid
  grid-flow-col
  auto-cols-[83.33%]
  flex-nowrap
  md:overflow-x-auto
  md:grid-flow-row
  md:auto-cols-auto
  md:grid-cols-2
  lg:grid-cols-4
  gap-4
  px-4
  md:overflow-visible
  md:pr-4
  relative
  pb-8
`

const GoToBlogBlock = tw.a`
  bg-teatips-light
  grow
  rounded
  shadow-xl
  inline-grid
  grid-rows-[1fr_auto]
  gap-4
  justify-center
  items-center
  shrink-0
  grow
  h-full
  md:w-full
  snap-center
  p-4
  no-underline
  text-text
  align-top
`

const GoToBlogBlockTitle = tw.h2`
  text-text
  no-underline
  mb-2
`

const GoToBlogBlockText = tw.p`
  whitespace-normal
`

const ReadMore = tw.span`
  flex
  justify-end
  align-center
  text-sm
  gap-2
  underline
  text-text
`

const Home = ({ page, collections, posts }) => {
  const orderedCollections = collections
    .slice()
    .sort((a: ICollectionFields, b: ICollectionFields) => {
      return (
        (a.homepageOrder || Number.MAX_SAFE_INTEGER) -
        (b.homepageOrder || Number.MAX_SAFE_INTEGER)
      )
    })

  return (
    <ShopLayout collections={collections}>
      <Head>
        <title>{page.seo.title || page.title} | Teatips</title>
        <meta name="description" content={page.seo.description} />
        <meta name="og:description" content={page.seo.description} />
        <meta
          name="og:image"
          content="https://cdn.shopify.com/s/files/1/0618/5353/2409/files/logo_teatips.jpg?v=1643232939"
        />
      </Head>

      <Title>Il negozio online del tè in foglia</Title>

      <Search placeholder="🔍 Cerca nel Negozio" />

      <UpperBandWrapper>
        <UpperBand className="bg-teatips-light block px-4 py-2 text-center rounded">
          Spedizioni standard gratuite sopra i 35€ 🚚
        </UpperBand>
      </UpperBandWrapper>

      <CollectionsWrapper>
        {orderedCollections
          .map((collection: ICollectionFields) => (
            <Collection key={collection.slug} {...collection} />
          ))}
      </CollectionsWrapper>

      <PageBody body={page.body} />

      <Cleaner>
        <BlogPreviewWrapper>
          <Link href="/blog" passHref={true}>
            <GoToBlogBlock>
              <div>
                <GoToBlogBlockTitle>
                  Il tè, questo sconosciuto
                </GoToBlogBlockTitle>
                <GoToBlogBlockText>
                  Qui puoi trovare una serie di articoli di blog che ho scritto,
                  ripondendo a domande come:{' '}
                  <strong>
                    Che cos&apos;è il tè? Da dove viene? Come viene prodotto?
                  </strong>{' '}
                  Ci sono anche consigli, ricette e curiosità. Scorri verso
                  destra per scoprire gli articoli più letti e amati dalla
                  community di Teatips.
                </GoToBlogBlockText>
              </div>
              <ReadMore>
                Vai al Blog
                <FiArrowRight className="text-xl text-text" />
              </ReadMore>
            </GoToBlogBlock>
          </Link>

          {posts.map((post: any, i: number) => (
            <BlogPostPreview key={i} {...post} />
          ))}
        </BlogPreviewWrapper>
      </Cleaner>
    </ShopLayout>
  )
}

export async function getStaticProps({ preview = false }) {
  const client = preview ? contentfulPreview : contentful

  const { data } = await shopify.query({
    query: gql`
      query getHomepage {
        page(handle: "homepage") {
          title
          seo {
            description
            title
          }
          body
        }
      }
    `,
  })

  // get blog posts
  const { items: posts } = await client.getEntries({
    content_type: 'page',
    'fields.content.sys.contentType.sys.id': 'post',
    'fields.showOnShop': true,
    include: 10,
  })

  const renderedPosts = posts.map((post: any) => ({
    fields: {
      title: post.fields.title,
      slug: post.fields.slug,
      content: {
        fields: {
          cover: post.fields.content.fields.cover,
          excerpt: documentToHtmlString(post.fields.content.fields.excerpt),
        },
      },
    },
  }))

  // get collections
  const { items: collections }: { items: Entry<ICollectionFields>[] } =
    await client.getEntries({
      content_type: 'collection',
      include: 1,
    })

  return {
    props: {
      page: data.page,
      collections: collections
        .map((collection) => collection.fields)
        .filter((collectionFields) => collectionFields.showOnShop),
      posts: renderedPosts,
    },
    revalidate: parseInt(process.env.REVALIDATION_INDEX_TIME),
  }
}

export default Home
